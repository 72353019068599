import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { User } from "@/pages/UserPage/components/User";
import { ROUTES } from "@/routes";
import { BaseIcon, Box } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
import LogoSmall from "@/shared/img/logo-small.png";
export var Header = observer(function () {
    var media = useMedia();
    var menu = useStoreContext(MenuStore);
    if (media.phone) {
        return (React.createElement(Box, { height: 72, display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px", marginX: "20px", gap: "22px" },
            React.createElement(Link, { to: generatePath(ROUTES.root) },
                React.createElement("img", { src: LogoSmall, width: "36px", height: "36px", alt: "logo" })),
            React.createElement(BaseIcon, { icon: "Burger", size: 24, onClick: menu.toggle })));
    }
    return React.createElement(User, null);
});
