import React from "react";
import { Amount } from "@/shared/components/Amount";
export var SubscriptionTitle = function (_a) {
    var amount = _a.amount, initialPaymentAmount = _a.initialPaymentAmount, currency = _a.currency, showInitial = _a.showInitial;
    if (!amount) {
        return (React.createElement(React.Fragment, null,
            showInitial && (React.createElement(React.Fragment, null,
                React.createElement(Amount, { from: currency, to: currency, amount: initialPaymentAmount.toString() }),
                " ")),
            showInitial ? "one-time" : "One-time"));
    }
    var trialText = (React.createElement(React.Fragment, null,
        "1 month on us, then",
        " ",
        React.createElement(Amount, { from: currency, to: currency, amount: amount.toString() }),
        "/mo"));
    var standardText = (React.createElement(React.Fragment, null,
        "First month",
        showInitial && (React.createElement(React.Fragment, null,
            " ",
            React.createElement(Amount, { from: currency, to: currency, amount: initialPaymentAmount.toString() }))),
        ", then",
        " ",
        React.createElement(Amount, { from: currency, to: currency, amount: amount.toString() }),
        "/mo"));
    return initialPaymentAmount ? standardText : trialText;
};
