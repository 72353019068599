import * as React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@/shared/components";
import { useToggler } from "@/shared/hooks/useToggler";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardListStore } from "@/shared/stores/CardListStore";
import { CardItem } from "@/shared/components/CardItem";
import { CardDetailsModal } from "@/pages/CardPage/components/CardDetailsModal";
import { NewCardButton } from "@/shared/components/NewCardButton";
export var Cards = observer(function () {
    var popup = useToggler();
    var cardList = useStoreContext(CardListStore);
    React.useEffect(function () {
        cardList.sync();
    }, [cardList]);
    var _a = React.useState(null), selectedCard = _a[0], setSelectedCard = _a[1];
    var onClick = function (card) {
        setSelectedCard(card);
        popup.on();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "16px", marginTop: "16px" },
            cardList.list.map(function (card) {
                return (React.createElement(CardItem, { key: card.cardName, card: card, onClick: onClick }));
            }),
            React.createElement(NewCardButton, null)),
        popup.enabled && selectedCard && (React.createElement(CardDetailsModal, { cardId: selectedCard.id, onClose: popup.off }))));
});
