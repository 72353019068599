import React from "react";
import { observer } from "mobx-react-lite";
import { action, runInAction } from "mobx";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStep, SubscriptionStore, } from "@/shared/stores/SubscriptionStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { PromoCodeInput } from "@/shared/components/PromoCodeInput";
import { onChange } from "@/shared/utils/input";
import { PaymentCurrencyDropdown } from "@/shared/components/PaymentCurrencyDropdown";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { SuccessPaymentPopup } from "@/pages/SubscriptionPage/components/SuccessPaymentPopup";
import { DiscountStore } from "@/shared/stores/DiscountStore";
import { SubscriptionTitle } from "@/pages/SubscriptionPage/components/SubscriptionTitle";
import { Amount } from "@/shared/components/Amount";
export var Checkout = observer(function () {
    var _a, _b, _c, _d, _e;
    var subscriptions = useStoreContext(SubscriptionStore);
    var accountList = useStoreContext(AccountListStore);
    var discount = useStoreContext(DiscountStore);
    var account = accountList.checking;
    var selectedSubscription = subscriptions.selectedSubscription;
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var accounts = useStoreContext(AccountListStore);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.list.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.list]);
    var currencies = currencyList.withWalletEnabled.filter(function (c) { return c.type === "CRYPTO"; });
    var onCurrencyChange = function (c, enoughFunds, convertedAmount) {
        runInAction(function () {
            subscriptions.selectedCurrency = c;
            subscriptions.enoughFunds = enoughFunds;
            subscriptions.convertedAmount = convertedAmount;
        });
    };
    React.useEffect(function () {
        if (account) {
            subscriptions.startPlan(account.account);
        }
    }, [account, subscriptions]);
    var applyPromoCode = action(function () {
        subscriptions.promoCodeApplied = true;
    });
    var clearPromoCode = action(function () {
        subscriptions.promoCodeApplied = false;
        subscriptions.promoCode = "";
    });
    var hasDiscount = discount.list && discount.list.length > 0;
    var discountedAmount = hasDiscount && selectedSubscription && discount.list[0].discount_percent
        ? (selectedSubscription.amount *
            (100 - discount.list[0].discount_percent)) /
            100
        : selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.amount;
    var discountedInitialAmount = hasDiscount && selectedSubscription && discount.list[0].discount_percent
        ? (selectedSubscription.initialPaymentAmount *
            (100 - discount.list[0].discount_percent)) /
            100
        : selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.initialPaymentAmount;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
                React.createElement(BaseIcon, { icon: "Back", size: 24, style: {
                        cursor: "pointer",
                    }, onClick: subscriptions.prevStep }),
                React.createElement(Typography, { textAlign: "center", type: "text28Bold" }, "Subscribe")),
            React.createElement(Box, { padding: "32px", bgColor: "general-white", borderRadius: "32px", marginTop: "32px" },
                selectedSubscription && (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
                    React.createElement(Typography, { type: "text32Bold" }, selectedSubscription.name),
                    React.createElement(Typography, { type: "text24" },
                        React.createElement(SubscriptionTitle, { amount: selectedSubscription.amount, initialPaymentAmount: selectedSubscription.initialPaymentAmount, currency: selectedSubscription.currency, showInitial: true })))),
                selectedSubscription &&
                    currencies.length > 0 &&
                    selectedSubscription.initialPaymentAmount > 0 && (React.createElement(Box, { marginTop: "32px" },
                    React.createElement(PaymentCurrencyDropdown, { totalAmount: discountedInitialAmount
                            ? discountedInitialAmount.toString() ||
                                "0"
                            : (discountedAmount === null || discountedAmount === void 0 ? void 0 : discountedAmount.toString()) ||
                                "0", selectedCurrency: subscriptions.selectedCurrency ||
                            currencies[0], balances: balanceList.data, currencies: currencies, onCurrencyChange: onCurrencyChange }))),
                selectedSubscription &&
                    !subscriptions.enoughFunds &&
                    selectedSubscription.initialPaymentAmount > 0 && (React.createElement(Box, { display: "flex", gap: "12px", sx: {
                        backgroundColor: "#F6F7F8",
                    }, borderRadius: "16px", alignItems: "center", padding: "16px", marginTop: "44px", boxSizing: "border-box" },
                    React.createElement(BaseIcon, { icon: "Warning", size: 44 }),
                    React.createElement(Typography, { color: "black-500", type: "text14" },
                        "You need to add",
                        " ", (_a = subscriptions.selectedCurrency) === null || _a === void 0 ? void 0 :
                        _a.shortName,
                        " ",
                        subscriptions.convertedAmount,
                        " to your",
                        " ", (_b = subscriptions.selectedCurrency) === null || _b === void 0 ? void 0 :
                        _b.description,
                        " ",
                        "wallet to get subscribed")))),
            React.createElement(Box, { padding: "32px", bgColor: "general-white", borderRadius: "32px", marginTop: "32px" },
                React.createElement(Box, null,
                    React.createElement(PromoCodeInput, { value: subscriptions.promoCode, onChange: onChange(action(function (val) {
                            if (!subscriptions.promoCodeApplied) {
                                subscriptions.promoCode = val;
                            }
                        })), applied: subscriptions.promoCodeApplied, onApply: function () {
                            applyPromoCode();
                        }, onClear: function () {
                            clearPromoCode();
                        }, isValid: subscriptions.isPromoCodeValid })),
                selectedSubscription && (React.createElement(Box, { marginTop: "16px" },
                    React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                        React.createElement(Typography, { color: "black-500", type: "text16" }, selectedSubscription.amount
                            ? "First month"
                            : "One-time"),
                        hasDiscount ? (selectedSubscription.initialPaymentAmount ? (React.createElement(Box, null,
                            React.createElement(Typography, { type: "text16", style: {
                                    textDecoration: "line-through",
                                } },
                                React.createElement(Amount, { from: selectedSubscription.currency, to: selectedSubscription.currency, amount: selectedSubscription.initialPaymentAmount.toString() })),
                            " ",
                            React.createElement(Typography, { type: "text16" },
                                React.createElement(Amount, { from: selectedSubscription.currency, to: selectedSubscription.currency, amount: discountedInitialAmount === null || discountedInitialAmount === void 0 ? void 0 : discountedInitialAmount.toString() })))) : (React.createElement(Typography, { type: "text16" }, "FREE"))) : (React.createElement(Typography, { type: "text16" },
                            React.createElement(Amount, { from: selectedSubscription.currency, to: selectedSubscription.currency, amount: selectedSubscription.initialPaymentAmount.toString() })))),
                    selectedSubscription.amount > 0 && (React.createElement(Box, { display: "flex", justifyContent: "space-between", marginTop: "16px" },
                        React.createElement(Typography, { type: "text16" }, "Monthly"),
                        React.createElement(Typography, { type: "text16" }, hasDiscount ? (React.createElement(Box, null,
                            React.createElement(Typography, { type: "text16", style: {
                                    textDecoration: "line-through",
                                } },
                                React.createElement(Amount, { from: selectedSubscription.currency, to: selectedSubscription.currency, amount: selectedSubscription.amount.toString() })),
                            " ",
                            React.createElement(Typography, { type: "text16" },
                                React.createElement(Amount, { from: selectedSubscription.currency, to: selectedSubscription.currency, amount: discountedAmount === null || discountedAmount === void 0 ? void 0 : discountedAmount.toString() })))) : (React.createElement(Typography, { type: "text16" },
                            React.createElement(Amount, { from: selectedSubscription.currency, to: selectedSubscription.currency, amount: selectedSubscription.amount.toString() }))))))))),
            React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "32px" }, subscriptions.enoughFunds ||
                !(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.initialPaymentAmount) ? (React.createElement(Button, { size: "l", loading: subscriptions.loading, onClick: function () {
                    var _a;
                    subscriptions.pay(account.account, (_a = discount.list[0]) === null || _a === void 0 ? void 0 : _a.code);
                } },
                "Get ", selectedSubscription === null || selectedSubscription === void 0 ? void 0 :
                selectedSubscription.name,
                " for",
                " ",
                (selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.initialPaymentAmount) ? (React.createElement(React.Fragment, null, (_c = subscriptions.selectedCurrency) === null || _c === void 0 ? void 0 :
                    _c.shortName,
                    " ",
                    React.createElement(Amount, { from: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.currency, to: (_d = subscriptions.selectedCurrency) === null || _d === void 0 ? void 0 : _d.slug, amount: discountedInitialAmount === null || discountedInitialAmount === void 0 ? void 0 : discountedInitialAmount.toString() }))) : ("FREE"))) : (React.createElement(Button, { size: "l", onClick: function () {
                    runInAction(function () {
                        subscriptions.step =
                            SubscriptionStep.AddFunds;
                    });
                } },
                "Add ", (_e = subscriptions.selectedCurrency) === null || _e === void 0 ? void 0 :
                _e.shortName,
                " and Get ",
                selectedSubscription.name)))),
        subscriptions.paymentSuccess && React.createElement(SuccessPaymentPopup, null)));
});
