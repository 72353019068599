import React from "react";
import { Popup } from "@/shared/components/Popup";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import frozen from "@/shared/img/frozen.png";
export var CardFrozenPopup = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement(Popup, { width: "480px" },
        React.createElement(Box, { padding: "32px", display: "flex", flexDirection: "column", alignItems: "center", gap: "32px", position: "relative" },
            React.createElement(BaseIcon, { icon: "Close", style: {
                    position: "absolute",
                    right: "32px",
                    top: "32px",
                }, size: 24, onClick: onClose }),
            React.createElement("img", { alt: "card-frozen", src: frozen, style: {
                    width: "100px",
                    height: "100px",
                } }),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "12px", alignItems: "center" },
                React.createElement(Typography, { type: "text28" }, "Card frozen"),
                React.createElement(Typography, { type: "text16", textAlign: "center" }, "No payments or withdrawals can be made until you unfreeze it\u2014whenever you\u2019re ready")),
            React.createElement(Button, { width: "100%", onClick: onClose, size: "l" }, "Got it"))));
};
