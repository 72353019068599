import React from "react";
import { Popup } from "@/shared/components/Popup";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
var AccountDetailsRow = function (_a) {
    var label = _a.label, details = _a.details;
    return (React.createElement(Box, { display: "flex", flexDirection: "column" },
        React.createElement(Typography, { type: "text14", color: "black-500" }, label),
        React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
            React.createElement(Typography, { type: "text16" }, details),
            React.createElement(BaseIcon, { icon: "Copy", size: 16, onClick: function () { return navigator.clipboard.writeText(details); } }))));
};
export var AccountDetailsPopup = function (_a) {
    var _b, _c;
    var account = _a.account, onClose = _a.onClose;
    var _d = React.useState((_b = account.addresses) === null || _b === void 0 ? void 0 : _b[0]), selectedAddress = _d[0], setSelectedAddress = _d[1];
    var renderAccountDetails = function () {
        switch (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.type) {
            case "SEPA": {
                var address = selectedAddress;
                return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "24px" },
                    address.accountHolderName && (React.createElement(AccountDetailsRow, { label: "Account holder name", details: address.accountHolderName })),
                    address.accountHolderAddress && (React.createElement(AccountDetailsRow, { label: "Account holder address", details: address.accountHolderAddress })),
                    address.bankAddress && (React.createElement(AccountDetailsRow, { label: "Bank address", details: address.bankAddress })),
                    address.bankName && (React.createElement(AccountDetailsRow, { label: "Bank name", details: address.bankName })),
                    address.bic && (React.createElement(AccountDetailsRow, { label: "BIC", details: address.bic })),
                    address.iban && (React.createElement(AccountDetailsRow, { label: "IBAN", details: address.iban })),
                    address.memo && (React.createElement(AccountDetailsRow, { label: "Memo", details: address.memo }))));
            }
            case "SWIFT": {
                var address = selectedAddress;
                return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "24px" },
                    address.accountNumber && (React.createElement(AccountDetailsRow, { label: "Account number", details: address.accountNumber })),
                    address.accountHolderName && (React.createElement(AccountDetailsRow, { label: "Account holder name", details: address.accountHolderName })),
                    address.accountHolderAddress && (React.createElement(AccountDetailsRow, { label: "Account holder address", details: address.accountHolderAddress })),
                    address.bankAddress && (React.createElement(AccountDetailsRow, { label: "Bank address", details: address.bankAddress })),
                    address.bankName && (React.createElement(AccountDetailsRow, { label: "Bank name", details: address.bankName })),
                    address.swiftBic && (React.createElement(AccountDetailsRow, { label: "SWIFT BIC", details: address.swiftBic })),
                    address.memo && (React.createElement(AccountDetailsRow, { label: "Memo", details: address.memo }))));
            }
            default:
                return null;
        }
    };
    return (React.createElement(Popup, null,
        React.createElement(Box, { padding: "32px", display: "flex", flexDirection: "column", gap: "32px" },
            React.createElement(Box, { display: "flex", gap: "8px" }, (_c = account.addresses) === null || _c === void 0 ? void 0 : _c.map(function (address) { return (React.createElement(Box, { key: address.type, borderRadius: "22px", padding: "8px 20px", bgColor: (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.type) === address.type
                    ? "deep-blue-500"
                    : "deep-blue-500-transparent-2", sx: {
                    cursor: "pointer",
                }, onClick: function () {
                    setSelectedAddress(address);
                } },
                React.createElement(Typography, { type: "text14", color: (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.type) === address.type
                        ? "general-white"
                        : "deep-blue-500" }, address.type))); })),
            React.createElement(Box, { borderRadius: "22px", bgColor: "black-50", padding: "24px" }, renderAccountDetails()),
            React.createElement(Button, { size: "l", variant: "LightBlue", onClick: onClose, width: "100%" }, "Close"))));
};
