import React from "react";
import { observer } from "mobx-react-lite";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { SubscriptionCard } from "@/pages/SubscriptionPage/components/Card";
import { RecurrentInvoiceStatus, } from "@/api/registration/types";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
export var ChoosePlan = observer(function () {
    var subscriptions = useStoreContext(SubscriptionStore);
    var navigate = useNavigate();
    var onSelect = function (subscription) {
        subscriptions.selectPlan(subscription);
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
            React.createElement(BaseIcon, { icon: "Back", size: 24, style: {
                    cursor: "pointer",
                }, onClick: function () { return navigate(ROUTES.root); } }),
            React.createElement(Typography, { textAlign: "center", type: "text28Bold" }, "Choose your perfect plan")),
        React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(3,260px)", marginTop: "72px", gap: "40px" }, subscriptions.available.map(function (subscription, index) { return (React.createElement(SubscriptionCard, { key: subscription.name, subscription: subscription, onSelect: onSelect, mostPopular: index === 1, current: subscriptions.my.some(function (s) {
                var _a;
                return (s.status === RecurrentInvoiceStatus.ACTIVE ||
                    s.status ===
                        RecurrentInvoiceStatus.TRIAL) &&
                    ((_a = s.subscriptionDetails) === null || _a === void 0 ? void 0 : _a.id) === subscription.id;
            }) })); }))));
});
