import { List, ListItemButton, Popover } from "@mui/material";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as React from "react";
import { defaultCountries, FlagImage, parseCountry, } from "react-international-phone";
import { Box } from "@/shared/components/Box";
import { FormControlInput } from "@/shared/components/FormControlInput";
import { Typography } from "@/shared/components/Typography";
import { useToggler } from "@/shared/hooks/useToggler";
import { Input } from "@/shared/components/Input";
import { BaseIcon } from "@/shared/components";
export var PhoneInput = function (_a) {
    var value = _a.value, error = _a.error, disabled = _a.disabled, onChange = _a.onChange;
    var divRef = React.useRef(null);
    var toggler = useToggler();
    var _b = React.useState(""), search = _b[0], setSearch = _b[1];
    var filteredCountries = search
        ? defaultCountries.filter(function (item) {
            return item[0].toLowerCase().includes(search.toLowerCase());
        })
        : defaultCountries;
    var handleSearchChange = function (e) {
        setSearch(e.target.value);
    };
    var _c = React.useState(""), phone = _c[0], setPhone = _c[1];
    var _d = React.useState({
        iso2: "us",
        dialCode: "1",
    }), country = _d[0], setCountry = _d[1];
    var handlePhoneChange = function (e) {
        var newPhone = e.target.value;
        try {
            var parsedPhone = PhoneNumberUtil.getInstance().parseAndKeepRawInput(newPhone);
            if (PhoneNumberUtil.getInstance().isValidNumber(parsedPhone)) {
                setPhone(parsedPhone.getNationalNumber().toString());
                setCountry({
                    dialCode: parsedPhone.getCountryCode().toString(),
                    iso2: PhoneNumberUtil.getInstance().getRegionCodeForCountryCode(parsedPhone.getCountryCode()).toLowerCase()
                });
            }
            else {
                setPhone(newPhone);
            }
        }
        catch (e) {
            setPhone(newPhone);
        }
        onChange(country.dialCode + newPhone);
    };
    function handleCountryChange(country) {
        setCountry(country);
    }
    return (React.createElement(Box, { ref: divRef, display: "flex", gap: "4px", flex: 1 },
        React.createElement(Box, { sx: { width: "140px" } },
            React.createElement(FormControlInput, { value: "    ", label: "Country code", disabled: disabled, onClick: toggler.on, InputProps: {
                    startAdornment: (React.createElement(Box, { display: "flex", gap: "16px", onClick: toggler.on },
                        React.createElement(Box, { display: "flex", gap: "8px" },
                            React.createElement(FlagImage, { iso2: country.iso2, style: {
                                    display: "flex",
                                    width: "24px",
                                    height: "24px",
                                } }),
                            React.createElement(Typography, null, "+".concat(country.dialCode))),
                        React.createElement(BaseIcon, { icon: "DropDown", style: {
                                position: "absolute",
                                right: "4px",
                            }, onClick: toggler.off }))),
                } })),
        React.createElement(Popover, { open: toggler.enabled, anchorEl: divRef.current, onClose: toggler.off, anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "left",
            }, slotProps: {
                paper: {
                    style: {
                        width: "366px",
                    },
                },
            } },
            React.createElement(List, { disablePadding: true, sx: {
                    width: "350px",
                    maxHeight: 500,
                    padding: "12px 16px 6px 16px",
                } },
                React.createElement(Input, { label: "Search for country", value: search, width: "calc(100% - 24px)", sx: { marginBottom: "6px" }, onChange: handleSearchChange }),
                filteredCountries.map(function (item) {
                    var country = parseCountry(item);
                    return (React.createElement(ListItemButton, { key: country.iso2, disableGutters: true, sx: {
                            width: "calc(100% - 16px)",
                            padding: "16px",
                        }, onClick: function () {
                            handleCountryChange(country);
                            toggler.off();
                        } },
                        React.createElement(Box, { display: "flex", justifyContent: "space-between", width: "100%" },
                            React.createElement(Box, { display: "flex", gap: "16px" },
                                React.createElement(FlagImage, { iso2: country.iso2, style: {
                                        display: "flex",
                                        width: "24px",
                                        height: "24px",
                                    } }),
                                React.createElement(Typography, { type: "text16", color: "black-1200", className: "ListItemText" }, country.name)),
                            React.createElement(Box, null,
                                React.createElement(Typography, { color: "black-500" },
                                    "+",
                                    country.dialCode)))));
                }))),
        React.createElement(FormControlInput, { label: "Phone number", value: phone, onChange: handlePhoneChange, error: error, disabled: disabled, width: "100%" })));
};
