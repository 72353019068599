export var PARTNER_ID = "4";
export var CLIENT_ID = "Vaelumiu4xuf7ohm";
export var ACQUIRING_API_BASE_URL = "https://api.pre-v2.vault.ist/acquiring";
export var APPROVER_API_BASE_URL = "https://api.pre-v2.vault.ist";
export var CARD_HOLDER_API_BASE_URL = "https://api.pre-v2.vault.ist/card-holder";
export var CURRENCY_API_BASE_URL = "https://api.pre-v2.vault.ist/currency";
export var REGISTRATION_API_BASE_URL = "https://api.pre-v2.vault.ist/reg";
export var WALLET_API_BASE_URL = "https://api.pre-v2.vault.ist/wallet";
export var BANK_ACCOUNT_API_BASE_URL = "https://api.pre-v2.vault.ist/bank";
export var ZEN_DESK_ID = "b1a87a80-c4a1-4b63-9cb8-bedbe6d4be3c";
export var ZEN_DESK_NAME = "Zenith";
