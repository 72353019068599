import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var OpenAccountButton = function (_a) {
    var onSubmit = _a.onSubmit;
    return (React.createElement(Box, { padding: "22px 20px", bgColor: "general-white", borderRadius: "22px", display: "flex", alignItems: "center", gap: "8px", sx: {
            cursor: "pointer",
        }, onClick: onSubmit },
        React.createElement(Box, { width: "68px", height: "48px", bgColor: "black-100", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "6px" },
            React.createElement(BaseIcon, { icon: "Bank", color: "deep-blue-500", size: 24 })),
        React.createElement(Typography, { type: "text16Bold", color: "deep-blue-500" }, "New account")));
};
