import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { SigninPageStore, SigninStep, } from "@/pages/SigninPage/stores/SigninPageStore";
import { ROUTES } from "@/routes";
import { Box, FormControlInput, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var EmailForm = observer(function () {
    var _a, _b;
    var page = useStoreContext(SigninPageStore);
    var handleEmailSubmit = function () {
        page.step = SigninStep.Password;
    };
    return (React.createElement(AuthForm, { onSubmit: handleEmailSubmit, title: "Sign in", desc: React.createElement(React.Fragment, null,
            React.createElement(Typography, { type: "text14", color: "black-500" },
                "Do not have an account?",
                " "),
            React.createElement(Link, { type: "text14", to: generatePath(ROUTES.root) }, "Create an account")), content: React.createElement(React.Fragment, null,
            React.createElement(Box, null,
                React.createElement(FormControlInput, { type: "text", label: "Email", error: (_a = page.errorsByKey.username) === null || _a === void 0 ? void 0 : _a[0], variantError: "alert", disabled: page.loading, value: page.email, onChange: onChange(action(function (value) {
                        page.email = value;
                    })) }),
                React.createElement(Box, { marginTop: "8px" },
                    React.createElement(Link, { color: "deep-blue-500", sx: { fontSize: "14px" }, to: generatePath(ROUTES.passwordReset) }, "Lost access to my email"))),
            React.createElement(Button, { type: "submit", width: "100%", error: (_b = page.errorsByKey.unknown) === null || _b === void 0 ? void 0 : _b[0], size: "l", 
                // disabled={!page.isEmailValid || !page.isPasswordValid || page.loading}
                disabled: !page.isEmailValid || page.loading }, "Continue")) }));
});
