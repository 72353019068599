var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { CardListStore } from "@/shared/stores/CardListStore";
import { CardActionButton } from "@/pages/CardPage/components/CardDetailsModal/CardActionButton";
import { CardFront } from "@/pages/CardPage/components/Card/CardFront";
import { CardBack } from "@/pages/CardPage/components/Card/CardBack";
import { generateQueryPath } from "@/shared/utils/path";
import { ExchangeType, ROUTES } from "@/routes";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardFrozenPopup } from "@/pages/CardPage/components/Popups/CardFrozenPopup";
import { useToggler } from "@/shared/hooks/useToggler";
import { CardStatus } from "@/api/cardHolder/types";
import { BlockCardPopup } from "@/pages/CardPage/components/Popups/BlockCardPopup";
import { ChangePinPopup } from "@/pages/CardPage/components/Popups/ChangePinPopup";
export var CardDetailsModal = observer(function (_a) {
    var cardId = _a.cardId, onClose = _a.onClose;
    var navigate = useNavigate();
    var frozenCardPopup = useToggler();
    var blockCardPopup = useToggler();
    var changePinPopup = useToggler();
    var updateLimitsPopup = useToggler();
    var cardList = useStoreContext(CardListStore);
    var _b = React.useState(true), isFront = _b[0], setIsFront = _b[1];
    var card = cardList.list.find(function (card) { return card.id === cardId; });
    if (!card) {
        return null;
    }
    var toggleSide = function () {
        setIsFront(!isFront);
    };
    var handleAddMoney = function () {
        navigate(generateQueryPath(ROUTES.exchange, {
            type: ExchangeType.Sell,
        }, {
            from: "usdt",
            to: "usdt",
            toCardId: card === null || card === void 0 ? void 0 : card.id,
        }));
    };
    var handleFreezeCard = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cardList.freeze(card.id)];
                case 1:
                    _a.sent();
                    frozenCardPopup.on();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleUnfreezeCard = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cardList.unfreeze(card.id)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleBlockCard = function () {
        blockCardPopup.on();
    };
    var handleChangePin = function () {
        changePinPopup.on();
    };
    var handleUpdateLimits = function () {
        updateLimitsPopup.on();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { width: "370px", position: "fixed", top: "32px", right: "32px", bgColor: "general-white", borderRadius: "22px", height: "calc(100vh - 64px)", zIndex: 99, sx: {
                boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.25)",
            } },
            React.createElement(Box, { padding: "16px 32px 32px 32px" },
                React.createElement(Box, { display: "flex", marginBottom: "24px", gap: "4px" },
                    React.createElement(BaseIcon, { icon: "Close", size: 24, onClick: onClose }),
                    React.createElement(Typography, null, card.cardName)),
                isFront ? (React.createElement(CardFront, { card: card, toggleSide: toggleSide })) : (React.createElement(CardBack, { card: card, toggleSide: toggleSide })),
                React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "10px" },
                    React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
                        React.createElement(BaseIcon, { icon: "Cloud", color: "black-500" }),
                        React.createElement(Typography, { type: "text16", color: "black-500" }, "Virtual"))),
                React.createElement(Box, { marginTop: "24px", display: "flex", justifyContent: "space-around" },
                    React.createElement(CardActionButton, { icon: "PlusSmall", label: "Add money", onClick: handleAddMoney }),
                    card.status !== CardStatus.FROZEN && (React.createElement(CardActionButton, { icon: "Freeze", label: "Freeze", onClick: handleFreezeCard })),
                    card.status === CardStatus.FROZEN && (React.createElement(CardActionButton, { icon: "Unfreeze", label: "Unfreeze", onClick: handleUnfreezeCard })),
                    React.createElement(CardActionButton, { icon: "Block", label: "Block", onClick: handleBlockCard })),
                React.createElement(Box, { marginTop: "24px" }))),
        frozenCardPopup.enabled && (React.createElement(CardFrozenPopup, { onClose: frozenCardPopup.off })),
        blockCardPopup.enabled && (React.createElement(BlockCardPopup, { cardId: card.id, onClose: blockCardPopup.off })),
        changePinPopup.enabled && (React.createElement(ChangePinPopup, { cardId: card.id, onClose: changePinPopup.off }))));
});
