import React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { SubscriptionDescription } from "@/pages/SubscriptionPage/components/Card/SubscriptionDescription";
import { Amount } from "@/shared/components/Amount";
import { SubscriptionTitle } from "@/pages/SubscriptionPage/components/SubscriptionTitle";
export var SubscriptionCard = function (_a) {
    var subscription = _a.subscription, onSelect = _a.onSelect, mostPopular = _a.mostPopular, current = _a.current;
    var amount = subscription.amount, initialPaymentAmount = subscription.initialPaymentAmount, currency = subscription.currency;
    return (React.createElement(Box, { padding: "36px 12px", borderRadius: "16px", bgColor: "general-white", display: "flex", flexDirection: "column", justifyContent: "space-between", position: "relative", paddingTop: "calc(36px + 16px)", sx: {
            border: current ? "2px solid black" : "none",
        } },
        mostPopular && (React.createElement(Box, { sx: {
                backgroundColor: "black",
                color: "yellow",
                borderRadius: "16px 16px 0 0",
                paddingY: "12px",
                position: "absolute",
                top: "-16px",
                left: "50%",
                transform: "translateX(-50%)",
                fontWeight: "Bold",
                width: "calc(100% + 4px)",
                display: "flex",
                justifyContent: "center",
            } }, "Most Popular")),
        React.createElement(Box, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: "4px" },
                React.createElement(Typography, { sx: {
                        fontSize: "18px",
                        fontWeight: "bold",
                    }, color: "black-1200" }, subscription.name.toUpperCase()),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "4px", alignItems: "center" },
                    React.createElement(Typography, { type: "text18", color: "deep-blue-500" }, initialPaymentAmount > 0 ? (React.createElement(Amount, { from: subscription.currency, to: subscription.currency, amount: initialPaymentAmount.toString() })) : ("FREE")),
                    React.createElement(Typography, { type: "text14" },
                        React.createElement(SubscriptionTitle, { amount: amount, initialPaymentAmount: initialPaymentAmount, currency: currency })))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "4px", marginTop: "16px" },
                React.createElement(Typography, { textAlign: "start" },
                    React.createElement(SubscriptionDescription, { description: subscription.description })))),
        React.createElement(Box, { marginTop: "52px", display: "flex", flexDirection: "column" },
            React.createElement(Button, { size: "l", onClick: function () { return onSelect(subscription); } },
                "Get ",
                subscription.name.toUpperCase()))));
};
