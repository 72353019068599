import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { CardStatus } from "@/api/cardHolder/types";
export var CardItem = function (_a) {
    var card = _a.card, onClick = _a.onClick;
    return (React.createElement(Box, { height: 96, padding: "24px 20px", display: "flex", gap: "8px", borderRadius: "22px", bgColor: "general-white", boxSizing: "border-box", onClick: function () {
            onClick(card);
        }, sx: {
            cursor: "pointer",
        } },
        React.createElement(Box, { width: "68px", height: "48px", bgColor: "black-1000", borderRadius: "6px", padding: "4px 4px 0 4px", boxSizing: "border-box", display: "flex", flexDirection: "column-reverse", justifyContent: "space-between" },
            React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Typography, { type: "text12", color: "general-white" }, card.last4),
                React.createElement(BaseIcon, { icon: "Visa", size: 24 })),
            React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                React.createElement(Box, { width: "20px", height: "20px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                    } },
                    React.createElement(BaseIcon, { icon: "Cloud", size: 16 })),
                card.status === CardStatus.FROZEN && (React.createElement(BaseIcon, { icon: "Freeze", size: 16 })))),
        React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "space-between", paddingY: "2px" },
            React.createElement(Typography, { type: "text16Bold" },
                React.createElement(Amount, { from: "usd", amount: card.balance })),
            card.status === CardStatus.ACTIVE && (React.createElement(Typography, { type: "text14", color: "black-400" }, card.cardName)),
            card.status === CardStatus.FROZEN && (React.createElement(Typography, { type: "text14", color: "general-orange" }, "Card is frozen")))));
};
