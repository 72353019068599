/* eslint-disable */
/* tslint:disable */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var HttpStatus;
(function (HttpStatus) {
    HttpStatus["Value100CONTINUE"] = "100 CONTINUE";
    HttpStatus["Value101SWITCHINGPROTOCOLS"] = "101 SWITCHING_PROTOCOLS";
    HttpStatus["Value102PROCESSING"] = "102 PROCESSING";
    HttpStatus["Value103EARLYHINTS"] = "103 EARLY_HINTS";
    HttpStatus["Value103CHECKPOINT"] = "103 CHECKPOINT";
    HttpStatus["Value200OK"] = "200 OK";
    HttpStatus["Value201CREATED"] = "201 CREATED";
    HttpStatus["Value202ACCEPTED"] = "202 ACCEPTED";
    HttpStatus["Value203NONAUTHORITATIVEINFORMATION"] = "203 NON_AUTHORITATIVE_INFORMATION";
    HttpStatus["Value204NOCONTENT"] = "204 NO_CONTENT";
    HttpStatus["Value205RESETCONTENT"] = "205 RESET_CONTENT";
    HttpStatus["Value206PARTIALCONTENT"] = "206 PARTIAL_CONTENT";
    HttpStatus["Value207MULTISTATUS"] = "207 MULTI_STATUS";
    HttpStatus["Value208ALREADYREPORTED"] = "208 ALREADY_REPORTED";
    HttpStatus["Value226IMUSED"] = "226 IM_USED";
    HttpStatus["Value300MULTIPLECHOICES"] = "300 MULTIPLE_CHOICES";
    HttpStatus["Value301MOVEDPERMANENTLY"] = "301 MOVED_PERMANENTLY";
    HttpStatus["Value302FOUND"] = "302 FOUND";
    HttpStatus["Value302MOVEDTEMPORARILY"] = "302 MOVED_TEMPORARILY";
    HttpStatus["Value303SEEOTHER"] = "303 SEE_OTHER";
    HttpStatus["Value304NOTMODIFIED"] = "304 NOT_MODIFIED";
    HttpStatus["Value305USEPROXY"] = "305 USE_PROXY";
    HttpStatus["Value307TEMPORARYREDIRECT"] = "307 TEMPORARY_REDIRECT";
    HttpStatus["Value308PERMANENTREDIRECT"] = "308 PERMANENT_REDIRECT";
    HttpStatus["Value400BADREQUEST"] = "400 BAD_REQUEST";
    HttpStatus["Value401UNAUTHORIZED"] = "401 UNAUTHORIZED";
    HttpStatus["Value402PAYMENTREQUIRED"] = "402 PAYMENT_REQUIRED";
    HttpStatus["Value403FORBIDDEN"] = "403 FORBIDDEN";
    HttpStatus["Value404NOTFOUND"] = "404 NOT_FOUND";
    HttpStatus["Value405METHODNOTALLOWED"] = "405 METHOD_NOT_ALLOWED";
    HttpStatus["Value406NOTACCEPTABLE"] = "406 NOT_ACCEPTABLE";
    HttpStatus["Value407PROXYAUTHENTICATIONREQUIRED"] = "407 PROXY_AUTHENTICATION_REQUIRED";
    HttpStatus["Value408REQUESTTIMEOUT"] = "408 REQUEST_TIMEOUT";
    HttpStatus["Value409CONFLICT"] = "409 CONFLICT";
    HttpStatus["Value410GONE"] = "410 GONE";
    HttpStatus["Value411LENGTHREQUIRED"] = "411 LENGTH_REQUIRED";
    HttpStatus["Value412PRECONDITIONFAILED"] = "412 PRECONDITION_FAILED";
    HttpStatus["Value413PAYLOADTOOLARGE"] = "413 PAYLOAD_TOO_LARGE";
    HttpStatus["Value413REQUESTENTITYTOOLARGE"] = "413 REQUEST_ENTITY_TOO_LARGE";
    HttpStatus["Value414URITOOLONG"] = "414 URI_TOO_LONG";
    HttpStatus["Value414REQUESTURITOOLONG"] = "414 REQUEST_URI_TOO_LONG";
    HttpStatus["Value415UNSUPPORTEDMEDIATYPE"] = "415 UNSUPPORTED_MEDIA_TYPE";
    HttpStatus["Value416REQUESTEDRANGENOTSATISFIABLE"] = "416 REQUESTED_RANGE_NOT_SATISFIABLE";
    HttpStatus["Value417EXPECTATIONFAILED"] = "417 EXPECTATION_FAILED";
    HttpStatus["Value418IAMATEAPOT"] = "418 I_AM_A_TEAPOT";
    HttpStatus["Value419INSUFFICIENTSPACEONRESOURCE"] = "419 INSUFFICIENT_SPACE_ON_RESOURCE";
    HttpStatus["Value420METHODFAILURE"] = "420 METHOD_FAILURE";
    HttpStatus["Value421DESTINATIONLOCKED"] = "421 DESTINATION_LOCKED";
    HttpStatus["Value422UNPROCESSABLEENTITY"] = "422 UNPROCESSABLE_ENTITY";
    HttpStatus["Value423LOCKED"] = "423 LOCKED";
    HttpStatus["Value424FAILEDDEPENDENCY"] = "424 FAILED_DEPENDENCY";
    HttpStatus["Value425TOOEARLY"] = "425 TOO_EARLY";
    HttpStatus["Value426UPGRADEREQUIRED"] = "426 UPGRADE_REQUIRED";
    HttpStatus["Value428PRECONDITIONREQUIRED"] = "428 PRECONDITION_REQUIRED";
    HttpStatus["Value429TOOMANYREQUESTS"] = "429 TOO_MANY_REQUESTS";
    HttpStatus["Value431REQUESTHEADERFIELDSTOOLARGE"] = "431 REQUEST_HEADER_FIELDS_TOO_LARGE";
    HttpStatus["Value451UNAVAILABLEFORLEGALREASONS"] = "451 UNAVAILABLE_FOR_LEGAL_REASONS";
    HttpStatus["Value500INTERNALSERVERERROR"] = "500 INTERNAL_SERVER_ERROR";
    HttpStatus["Value501NOTIMPLEMENTED"] = "501 NOT_IMPLEMENTED";
    HttpStatus["Value502BADGATEWAY"] = "502 BAD_GATEWAY";
    HttpStatus["Value503SERVICEUNAVAILABLE"] = "503 SERVICE_UNAVAILABLE";
    HttpStatus["Value504GATEWAYTIMEOUT"] = "504 GATEWAY_TIMEOUT";
    HttpStatus["Value505HTTPVERSIONNOTSUPPORTED"] = "505 HTTP_VERSION_NOT_SUPPORTED";
    HttpStatus["Value506VARIANTALSONEGOTIATES"] = "506 VARIANT_ALSO_NEGOTIATES";
    HttpStatus["Value507INSUFFICIENTSTORAGE"] = "507 INSUFFICIENT_STORAGE";
    HttpStatus["Value508LOOPDETECTED"] = "508 LOOP_DETECTED";
    HttpStatus["Value509BANDWIDTHLIMITEXCEEDED"] = "509 BANDWIDTH_LIMIT_EXCEEDED";
    HttpStatus["Value510NOTEXTENDED"] = "510 NOT_EXTENDED";
    HttpStatus["Value511NETWORKAUTHENTICATIONREQUIRED"] = "511 NETWORK_AUTHENTICATION_REQUIRED";
})(HttpStatus || (HttpStatus = {}));
export var Provider;
(function (Provider) {
    Provider["PFSCARD"] = "PFSCARD";
    Provider["FINCI"] = "FINCI";
    Provider["STRIPE"] = "STRIPE";
    Provider["REAP"] = "REAP";
})(Provider || (Provider = {}));
/** Card type. */
export var CardType;
(function (CardType) {
    CardType["VIRTUAL"] = "VIRTUAL";
    CardType["PHYSICAL"] = "PHYSICAL";
})(CardType || (CardType = {}));
export var CardStatus;
(function (CardStatus) {
    CardStatus["INIT"] = "INIT";
    CardStatus["PENDING"] = "PENDING";
    CardStatus["ISSUED"] = "ISSUED";
    CardStatus["ACTIVE"] = "ACTIVE";
    CardStatus["FROZEN"] = "FROZEN";
    CardStatus["LOST"] = "LOST";
    CardStatus["STOLEN"] = "STOLEN";
    CardStatus["INACTIVE"] = "INACTIVE";
    CardStatus["CLOSED"] = "CLOSED";
    CardStatus["REJECTED"] = "REJECTED";
})(CardStatus || (CardStatus = {}));
export var CardRequestStatus;
(function (CardRequestStatus) {
    CardRequestStatus["INIT"] = "INIT";
    CardRequestStatus["PENDING"] = "PENDING";
    CardRequestStatus["ISSUED"] = "ISSUED";
    CardRequestStatus["REJECTED"] = "REJECTED";
})(CardRequestStatus || (CardRequestStatus = {}));
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["INIT"] = "INIT";
    TransactionStatus["AUTHORIZED"] = "AUTHORIZED";
    TransactionStatus["CLEARED"] = "CLEARED";
    TransactionStatus["DECLINED"] = "DECLINED";
    TransactionStatus["VOID"] = "VOID";
})(TransactionStatus || (TransactionStatus = {}));
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
var HttpClient = /** @class */ (function () {
    function HttpClient(_a) {
        if (_a === void 0) { _a = {}; }
        var _this = this;
        var securityWorker = _a.securityWorker, secure = _a.secure, format = _a.format, axiosConfig = __rest(_a, ["securityWorker", "secure", "format"]);
        this.securityData = null;
        this.setSecurityData = function (data) {
            _this.securityData = data;
        };
        this.request = function (_a) { return __awaiter(_this, void 0, Promise, function () {
            var secureParams, _b, requestParams, responseFormat;
            var secure = _a.secure, path = _a.path, type = _a.type, query = _a.query, format = _a.format, body = _a.body, params = __rest(_a, ["secure", "path", "type", "query", "format", "body"]);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (typeof secure === "boolean" ? secure : this.secure) &&
                            this.securityWorker;
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.securityWorker(this.securityData)];
                    case 1:
                        _b = (_c.sent());
                        _c.label = 2;
                    case 2:
                        secureParams = (_b) ||
                            {};
                        requestParams = this.mergeRequestParams(params, secureParams);
                        responseFormat = format || this.format || undefined;
                        if (type === ContentType.FormData &&
                            body &&
                            body !== null &&
                            typeof body === "object") {
                            body = this.createFormData(body);
                        }
                        if (type === ContentType.Text &&
                            body &&
                            body !== null &&
                            typeof body !== "string") {
                            body = JSON.stringify(body);
                        }
                        return [2 /*return*/, this.instance.request(__assign(__assign({}, requestParams), { headers: __assign(__assign({}, (requestParams.headers || {})), (type && type !== ContentType.FormData
                                    ? { "Content-Type": type }
                                    : {})), params: query, responseType: responseFormat, data: body, url: path }))];
                }
            });
        }); };
        this.instance = axios.create(__assign(__assign({}, axiosConfig), { baseURL: axiosConfig.baseURL ||
                "https://gw.api-stg.evercash.eu/card-holder" }));
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    HttpClient.prototype.mergeRequestParams = function (params1, params2) {
        var method = params1.method || (params2 && params2.method);
        return __assign(__assign(__assign(__assign({}, this.instance.defaults), params1), (params2 || {})), { headers: __assign(__assign(__assign({}, ((method &&
                this.instance.defaults.headers[method.toLowerCase()]) ||
                {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    };
    HttpClient.prototype.stringifyFormItem = function (formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return "".concat(formItem);
        }
    };
    HttpClient.prototype.createFormData = function (input) {
        var _this = this;
        return Object.keys(input || {}).reduce(function (formData, key) {
            var property = input[key];
            var propertyContent = property instanceof Array ? property : [property];
            for (var _i = 0, propertyContent_1 = propertyContent; _i < propertyContent_1.length; _i++) {
                var formItem = propertyContent_1[_i];
                var isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : _this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    };
    return HttpClient;
}());
export { HttpClient };
/**
 * @title Card-holder service
 * @version 0.0.11-b312b1b2
 * @baseUrl https://gw.api-stg.evercash.eu/card-holder
 *
 * API for interaction with different card-issuing providers.
 */
var CardHolderApi = /** @class */ (function (_super) {
    __extends(CardHolderApi, _super);
    function CardHolderApi() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * @description List all card offers for current user. Returns empty list if no cards offers found.
         *
         * @tags Card Offers
         * @name ListCardOffers1
         * @summary List card offers
         * @request GET:/
         * @secure
         */
        _this.listCardOffers1 = function (params) {
            if (params === void 0) { params = {}; }
            return _this.request(__assign({ path: "/cardoffer", method: "GET", secure: true, format: "json" }, params));
        };
        /**
         * @description Create card request for current user. PII information is required for some providers. If service is in standalone mode, PII must be provided in encrypted form. See data encryption guide page. Card type may be different for different providers. Check agreement with provider. `VIRTUAL` card - card for online payments only, some providers allows to issue physical card later. `PHYSICAL` card - card for online and offline payments. May be issued for some providers only. Physical card delivery address is required in PII
         *
         * @tags Card Requests
         * @name CreateCardRequest1
         * @summary Create card request
         * @request POST:/
         * @secure
         */
        _this.createCardRequest1 = function (data, params) {
            if (params === void 0) { params = {}; }
            return _this.request(__assign({ path: "/cardrequest", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
        };
        _this.admin = {
            /**
             * @description Prefund card with balance on master account. Only available for Prepaid cards on certain providers. Check agreement with provider.
             *
             * @tags Admin Cards
             * @name AdminUpdatePhone
             * @summary Prefund card
             * @request PUT:/admin/cardholder/card/{cardId}/phone
             * @secure
             */
            adminUpdatePhone: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/phone"), method: "PUT", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Prefund card with balance on master account. Only available for Prepaid cards on certain providers. Check agreement with provider.
             *
             * @tags Admin Cards
             * @name AdminUpdateEmail
             * @summary Prefund card
             * @request PUT:/admin/cardholder/card/{cardId}/email
             * @secure
             */
            adminUpdateEmail: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/email"), method: "PUT", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags Admin Configuration
             * @name UploadMccCodes
             * @summary Upload MCC codes
             * @request POST:/admin/config/mcc
             */
            uploadMccCodes: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/config/mcc", method: "POST", body: data, type: ContentType.FormData }, params));
            },
            /**
             * @description List all cards.
             *
             * @tags Admin Cards
             * @name AdminListCards
             * @summary List cards
             * @request GET:/admin/cardholder/card
             * @secure
             */
            adminListCards: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card", method: "GET", query: query, secure: true }, params));
            },
            /**
             * @description Create card for user. PII information is required for some providers. If service is in standalone mode, PII must be provided in encrypted form. See data encryption manual page.
             *
             * @tags Admin Cards
             * @name AdminCreateCard
             * @summary Create card
             * @request POST:/admin/cardholder/card
             * @secure
             */
            adminCreateCard: function (query, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card", method: "POST", query: query, body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Get top-up information. Only available for Prepaid cards on certain providers. Check agreement with provider.
             *
             * @tags Admin Cards
             * @name AdminGetTopUpInformation
             * @summary Get top-up information
             * @request POST:/admin/cardholder/card/{cardId}/top-up-information
             * @secure
             */
            adminGetTopUpInformation: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/top-up-information"), method: "POST", secure: true, format: "json" }, params));
            },
            /**
             * @description Prefund card with balance on master account. Only available for Prepaid cards on certain providers. Check agreement with provider.
             *
             * @tags Admin Cards
             * @name AdminPrefund
             * @summary Prefund card
             * @request POST:/admin/cardholder/card/{cardId}/prefund
             * @secure
             */
            adminPrefund: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/prefund"), method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Reset card PIN. May be encrypted.
             *
             * @tags Admin Cards
             * @name AdminResetPin
             * @summary Reset card PIN
             * @request POST:/admin/cardholder/card/{cardId}/pin/reset
             * @secure
             */
            adminResetPin: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/pin/reset"), method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Change card status.
             *
             * @tags Admin Cards
             * @name AdminUpdateCardStatus
             * @summary Change card status
             * @request POST:/admin/cardholder/card/{cardId}/change-status
             * @secure
             */
            adminUpdateCardStatus: function (cardId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/change-status"), method: "POST", query: query, secure: true }, params));
            },
            /**
             * @description Get card transactions for the specified period. If no period is specified, transactions from the current day are returned.
             *
             * @tags Admin Transactions
             * @name AdminGetCardTransactions
             * @summary Get card transactions
             * @request GET:/admin/transactions
             * @secure
             */
            adminGetCardTransactions: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/transactions", method: "GET", query: query, secure: true }, params));
            },
            /**
             * @description Get Master account balance
             *
             * @tags Admin Provider Config
             * @name AdminGetMasterAccountBalance
             * @summary Get Master account balance
             * @request GET:/admin/provider/{provider}/balance
             * @secure
             */
            adminGetMasterAccountBalance: function (provider, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/provider/".concat(provider, "/balance"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Get card details.
             *
             * @tags Admin Cards
             * @name AdminGetCard
             * @summary Get card details
             * @request GET:/admin/cardholder/card/{cardId}
             * @secure
             */
            adminGetCard: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId), method: "GET", secure: true }, params));
            },
            /**
             * @description Get card transactions.
             *
             * @tags Admin Cards
             * @name AdminGetCardTransactions1
             * @summary Get card transactions
             * @request GET:/admin/cardholder/card/{cardId}/transactions
             * @secure
             */
            adminGetCardTransactions1: function (cardId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/transactions"), method: "GET", query: query, secure: true }, params));
            },
            /**
             * @description Get card sensitive details. May be encrypted.
             *
             * @tags Admin Cards
             * @name AdminGetCardSensitiveDetails
             * @summary Get card sensitive details
             * @request GET:/admin/cardholder/card/{cardId}/sensitive-details
             * @secure
             */
            adminGetCardSensitiveDetails: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/sensitive-details"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Get card PIN. May be encrypted.
             *
             * @tags Admin Cards
             * @name AdminGetPin
             * @summary Get card PIN
             * @request GET:/admin/cardholder/card/{cardId}/pin
             * @secure
             */
            adminGetPin: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/pin"), method: "GET", secure: true }, params));
            },
            /**
             * @description Get card details.
             *
             * @tags Admin Cards
             * @name AdminGetCardDetails
             * @summary Get card details
             * @request GET:/admin/cardholder/card/{cardId}/details
             * @secure
             */
            adminGetCardDetails: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/details"), method: "GET", secure: true }, params));
            },
            /**
             * @description Get card balance. Only available for Prepaid cards on certain providers. Check agreement with provider.
             *
             * @tags Admin Cards
             * @name AdminGetCardBalance
             * @summary Get card balance
             * @request GET:/admin/cardholder/card/{cardId}/balance
             * @secure
             */
            adminGetCardBalance: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/cardholder/card/".concat(cardId, "/balance"), method: "GET", secure: true }, params));
            },
        };
        _this.cardholder = {
            /**
             * @description Process webhook event
             *
             * @tags Webhook
             * @name ProcessWebhook
             * @summary Process webhook event
             * @request POST:/cardholder/webhook/{provider}
             */
            processWebhook: function (provider, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/webhook/".concat(provider), method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * @description Simulate callback
             *
             * @tags Webhook
             * @name SimulateCallback
             * @summary Simulate callback
             * @request POST:/cardholder/webhook/simulateCallback
             */
            simulateCallback: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/webhook/simulateCallback", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * @description List all cards for current user. Returns empty list if no cards found.
             *
             * @tags Cards
             * @name ListCards
             * @summary List cards
             * @request GET:/cardholder/card
             * @secure
             */
            listCards: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Create card for current user. PII information is required for some providers. If service is in standalone mode, PII must be provided in encrypted form. See data encryption guide page. Card type may be different for different providers. Check agreement with provider. `VIRTUAL` card - card for online payments only, some providers allows to issue physical card later. `PHYSICAL` card - card for online and offline payments. May be issued for some providers only. Physical card delivery address is required in PII
             *
             * @tags Cards
             * @name CreateCard
             * @summary Create card
             * @request POST:/cardholder/card
             * @deprecated
             * @secure
             */
            createCard: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Reset card PIN. Returns new PIN. May return encrypted object that must be decoded by user secret phrase.
             *
             * @tags Cards
             * @name ResetPin
             * @summary Reset card PIN
             * @request POST:/cardholder/card/{cardId}/pin/reset
             * @secure
             */
            resetPin: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/pin/reset"), method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Get current card limits as cap and current state if provider supports
             *
             * @tags Cards
             * @name GetCardLimits
             * @summary Get card limits
             * @request GET:/cardholder/card/{cardId}/limits
             * @secure
             */
            getCardLimits: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/limits"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Update card limits as cap and current state if provider supports
             *
             * @tags Cards
             * @name UpdateCardLimits
             * @summary Update card limits
             * @request POST:/cardholder/card/{cardId}/limits
             * @secure
             */
            updateCardLimits: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/limits"), method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Change the card type, allows to change the card type from virtual to physical and request delivery.
             *
             * @tags Cards
             * @name ChangeCardType
             * @summary Change card type
             * @request POST:/cardholder/card/{cardId}/change-type
             * @secure
             */
            changeCardType: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/change-type"), method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Change card status.
             *
             * @tags Cards
             * @name UpdateCardStatus
             * @summary Change card status
             * @request POST:/cardholder/card/{cardId}/change-status
             * @secure
             */
            updateCardStatus: function (cardId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/change-status"), method: "POST", query: query, secure: true }, params));
            },
            /**
             * @description Activate physical card, not applicable for virtual cards and some providers.
             *
             * @tags Cards
             * @name ActivateCard
             * @summary Activate card
             * @request POST:/cardholder/card/{cardId}/activate
             * @secure
             */
            activateCard: function (cardId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/activate"), method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Get single card info.
             *
             * @tags Cards
             * @name GetCard
             * @summary Get card
             * @request GET:/cardholder/card/{cardId}
             * @secure
             */
            getCard: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Get card transactions.
             *
             * @tags Cards
             * @name GetCardTransactionsByCard
             * @summary Get card transactions
             * @request GET:/cardholder/card/{cardId}/transactions
             * @secure
             */
            getCardTransactionsByCard: function (cardId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/transactions"), method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * @description Get top-up information. Only available for Prepaid cards on certain providers. Check agreement with provider.
             *
             * @tags Cards
             * @name GetTopUpInformation
             * @summary Get top-up information
             * @request GET:/cardholder/card/{cardId}/top-up-information
             * @secure
             */
            getTopUpInformation: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/top-up-information"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Get card sensitive details.  May return encrypted object that must be decoded by user secret phrase.
             *
             * @tags Cards
             * @name GetCardSensitiveDetails
             * @summary Get card sensitive details
             * @request GET:/cardholder/card/{cardId}/sensitive-details
             * @secure
             */
            getCardSensitiveDetails: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/sensitive-details"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Get card PIN. May return encrypted object that must be decoded by user secret phrase.
             *
             * @tags Cards
             * @name GetPin
             * @summary Get card PIN
             * @request GET:/cardholder/card/{cardId}/pin
             * @secure
             */
            getPin: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/pin"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Get card sensitive details.
             *
             * @tags Cards
             * @name GetCardDetails
             * @summary Get card details
             * @request GET:/cardholder/card/{cardId}/details
             * @secure
             */
            getCardDetails: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/details"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Get card balance. Only available for Prepaid cards on certain providers. Check agreement with provider.
             *
             * @tags Cards
             * @name GetCardBalance
             * @summary Get card balance
             * @request GET:/cardholder/card/{cardId}/balance
             * @secure
             */
            getCardBalance: function (cardId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardholder/card/".concat(cardId, "/balance"), method: "GET", secure: true, format: "json" }, params));
            },
        };
        _this.transaction = {
            /**
             * @description Get card transactions for the specified period. If no period is specified, transactions from the current day are returned.
             *
             * @tags Transactions
             * @name GetCardTransactions
             * @summary Get card transactions
             * @request GET:/transaction
             * @secure
             */
            getCardTransactions: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/transaction", method: "GET", query: query, secure: true }, params));
            },
        };
        _this.card = {
            /**
             * @description List all card request for current user. Returns empty list if no cards found.
             *
             * @tags Card Requests
             * @name ListCardRequests
             * @summary List card requests
             * @request GET:/card
             * @secure
             */
            listCardRequests: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/cardrequest", method: "GET", secure: true, format: "json" }, params));
            },
        };
        return _this;
    }
    return CardHolderApi;
}(HttpClient));
export { CardHolderApi };
