import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var CardSensitiveInfo = function (_a) {
    var sensitiveDetails = _a.sensitiveDetails;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "8px", borderRadius: "16px" },
            React.createElement(Typography, { type: "text20", color: "general-white", fontWeight: 400 }, sensitiveDetails.cardNumber),
            React.createElement(Box, { borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                    cursor: "pointer",
                }, width: "24px", height: "24px", onClick: function () {
                    var _a;
                    return navigator.clipboard.writeText((_a = sensitiveDetails.cardNumber) !== null && _a !== void 0 ? _a : "");
                } },
                React.createElement(BaseIcon, { icon: "CopyLight", size: 16 }))),
        React.createElement(Box, { display: "flex", gap: "16px" },
            React.createElement(Box, { flex: "1", display: "flex", alignItems: "center", gap: "8px", borderRadius: "16px" },
                React.createElement(Typography, { type: "text20", color: "general-white", fontWeight: 400 }, sensitiveDetails.expDate),
                React.createElement(Box, { borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "24px", height: "24px", sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                        cursor: "pointer",
                    }, onClick: function () {
                        var _a;
                        return navigator.clipboard.writeText((_a = sensitiveDetails.expDate) !== null && _a !== void 0 ? _a : "");
                    } },
                    React.createElement(BaseIcon, { icon: "CopyLight", size: 16 }))),
            React.createElement(Box, { flex: "1", display: "flex", alignItems: "center", gap: "8px", borderRadius: "16px" },
                React.createElement(Typography, { type: "text20", color: "general-white", fontWeight: 400 }, sensitiveDetails.cvc),
                React.createElement(Box, { borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "24px", height: "24px", sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                        cursor: "pointer",
                    }, onClick: function () {
                        var _a;
                        return navigator.clipboard.writeText((_a = sensitiveDetails.cvc) !== null && _a !== void 0 ? _a : "");
                    } },
                    React.createElement(BaseIcon, { icon: "CopyLight", size: 16 }))))));
};
